<template>
    <Button label="Atualizar" icon="pi pi-pencil" @click="toAtualizar()" v-if="$temAcessoView('PARAM-03')" />
</template>

<script>
export default {
    props: {
        parametro: {
            type: Object,
        },
    },

    methods: {
        toAtualizar() {
            this.$router.push({
                name: `Parametros_Atualizar`,
                params: {
                    id: this.parametro.parametroId,
                },
            });
        },
    },
};
</script>
